.nav-tablet-area {
	width: 100%;
	background: rgba(36, 40, 42, 0.97);
	color: @color-white;
	position: absolute;
	transition: transform .3s ease;
	transform: scaleY(0);
	transform-origin: top;
}

.nav-tablet-area.open {
	transform: scaleY(1);
	z-index: 3;
}

.nav-tablet-area-inner {
	display: flex;
}

.nav-tablet-territories {
	width: @nav-desktop-menu-width + @nav-submenu-button-width;
	flex-shrink: 0;
}

.nav-tablet-territory-description {
	flex-grow: 1;
	display: flex;
	align-items: center;
	flex-flow: column;
	margin-top: @padding-m;
	margin-bottom: @padding-m;
}

.nav-tablet-territory,
.nav-tablet-territory-inner,
.nav-tablet-regions,
.nav-tablet-region {
	width: 100%;
}

.nav-tablet-territory {
	border-bottom: 1px solid @color-brand-grey;
}

.nav-tablet-territory .nav-button .fa-light {
	width: @nav-submenu-button-width;
	font-size: 16px;
}

.nav-tablet-region {
	border-top: 1px solid @color-brand-grey;
}

.nav-tablet-territory-inner {
	display: flex;
	align-items: baseline;
}

.nav-tablet-territory-inner.active {
	background: #000;
	color: @color-highlight;
}

.nav-tablet-link {
	padding: @padding-i;
	flex-grow: 1;
	display: block;
}

.nav-tablet-territory .nav-button {
	padding: 3px @padding-s;
	//border-left: 1px solid @wp-nav-menu-border;
	text-align: center;
}

.nav-tablet-region .nav-tablet-link {
	padding-left: @padding-l;
}
