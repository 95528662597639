.nav-description-image {
	display: flex;
	justify-content: flex-start;
	align-items: flex-end;

	background-position: center;
	background-repeat: no-repeat;
	margin-bottom: @padding-m;
	border-radius: @border-radius;
	position: relative;
}

.nav-description-title {
	font-family: @font-handdrawn;
	letter-spacing: @font-handdrawn-letterspacing;
	font-size: 60px;
}

.nav-description-portrait {
	border-radius: 50%;
	position: absolute;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

.nav-description-text {
	line-height: @line-height;
}

.nav-description-expert-name {
	font-weight: @font-weight-bold;
	margin: @padding-s 0 2 * @padding-m 0;
}

.nav-tablet-description {
	max-width: 460px + 4 * @padding-i + 2 * @wp-fs-tablet-base;
	font-size: @wp-fs-tablet-s;
	padding-left: 2 * @padding-i + @wp-fs-tablet-base;
	padding-right: 2 * @padding-i + @wp-fs-tablet-base;
	padding-top: @padding-m;
}

.nav-desktop-description .nav-description-image {
	width: 2 * @nav-desktop-menu-width;
	height: 264px;
	padding: @padding-m;
	background-size: contain;
}

.nav-desktop-description .nav-description-portrait {
	width: 180px;
	height: 180px;
	bottom: -90px;
	right: -90px;
}

.nav-tablet-description .nav-description-image {
	width: 100%;
	padding-bottom: 60%;
	background-size: cover;
}

.nav-tablet-description .nav-description-portrait {
	width: 140px;
	height: 140px;
	bottom: -50px;
	right: -30px;
}

.nav-tablet-description .nav-description-title {
	position: absolute;
	left: @padding-s;
	bottom: @padding-s;
}

.nav-tablet-description .nav-description-expert-name {
	margin: @padding-xs 0 @padding-s 0;
}

.nav-description-button-bar {
	display: flex;
	justify-content: center;
	margin-top: @padding-m;
}

.nav-tablet-description-button {
	padding: @padding-m;
	font-size: @wp-fs-base;
	width: 70%;
}
