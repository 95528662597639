@charset "utf-8";

@import "@v3/geko/src/less/anim.less";
@import "@v3/geko/src/less/variablesInternal.less";
@import "@v3/geko/src/less/variables.less";

@import "../../../common/src/less/genericOverrides.less";
@import "../../../common/src/less/variables.less";

@import "inc/description.less";
@import "inc/desktop.less";
@import "inc/mobile.less";
@import "inc/tablet.less";

.nav {
	background: @color-white;
	position: relative;
}

.nav-inner {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 100%;
}

.nav-inner.tablet {
	border-bottom: 1px solid @color-brand-grey;
}

// Only Desktop
@media screen and (min-width: @breakpoint-desktop-min) {
	.nav {
		height: @nav-desktop-height;
	}

	.nav-inner {
		width: @content-width;
		margin: 0 auto;
		position: relative;
	}
	.nav-logo {
		height: 40px;
	}
	.nav-menu-item-link:hover {
		background: rgba(36, 40, 42, 0.97);
		color: @color-highlight;
	}
}

// Only Tablets
@media screen and (min-width: @breakpoint-tablet-min) and (max-width: @breakpoint-tablet-max) {
	.nav {
		//height: 60px;
	}
	.nav-logo {
		height: 40px;
	}
}

// Only Tablets and Desktop
@media screen and (min-width: @breakpoint-tablet-min) {
	.nav-menu {
		display: flex;
		flex-flow: row nowrap;
		height: @nav-desktop-height;
	}

	.nav-menu-item-link {
		height: @nav-desktop-height;
		display: block;
		font-weight: @font-weight-bold;
		padding: 0 @padding-m;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.nav-menu-item-link.active {
		background: rgba(36, 40, 42, 0.97);
		color: @color-highlight;
	}
}
