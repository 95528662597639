.nav-desktop-area {
	z-index: 5;
}

.nav-desktop-regions,
.nav-desktop-region-description,
.nav-desktop-territory-description {
	display: none;
}

.nav-desktop-area {
	display: none;
	color: @color-white;
	position: absolute;
	top: @nav-desktop-height;
	left: 0;
	width: 100%;
	height: calc(100vh - 136px);
	// Evil Hack, to position nav-desktop-region-description relative to this element
	transition: transform 0s ease;
	transform: rotate(0deg);
}

.nav-desktop-backdrop {
	display: none;
	background: rgba(36, 40, 42, 0.97);
	position: absolute;
	top: @nav-desktop-height;

	height: calc(100vh - 136px);
	z-index: 4;

	width: calc(100vw - var(--scrollbar-width));
	left: 50%;
	right: 50%;
	margin-left: calc(-50vw + var(--scrollbar-width) / 2);
	margin-right: calc(-50vw + var(--scrollbar-width) / 2);
}

// NOTE necessary because of the border-top of nav-desktop-regions
.nav-desktop-territories {
	border-top: 1px solid transparent;
	height: calc(100vh - 136px);
	overflow-y: auto;
}

// We need to limit the width to place scrollbar aside the list elements instead of the right side of the content area
.nav-desktop-area.restricted .nav-desktop-territories {
	width: @nav-desktop-menu-width + 16px; // 16px are for vertical scrollbar width, to avoid horizontal scrollbar
}

.nav-desktop-territory {
	position: relative;
	width: @nav-desktop-menu-width;
}

// If you change anything which affests the height of this element,
// you have to adjust hardcoded height in menuVersion in DesktopList.tsx
.nav-desktop-territory > a,
.nav-desktop-region > a {
	display: block;
	padding: @padding-m;
	width: @nav-desktop-menu-width;
	border-top: 2px solid transparent;
	border-bottom: 1px solid @color-brand-grey;
	transition: all .3s ease-in;
}

.nav-desktop-territory-description,
.nav-desktop-region-description {
	position: fixed;
	right: 100px;
	top: 60px;
	width: 2 * @nav-desktop-menu-width;
	transition: all .15s ease-in;
}

.nav-desktop-opener:hover .nav-desktop-area,
.nav-menu-item-link:hover ~ .nav-desktop-backdrop,
.nav-desktop-area:hover ~ .nav-desktop-backdrop,
.nav-desktop-area.full .nav-desktop-territory:hover .nav-desktop-regions,
.nav-desktop-region:hover .nav-desktop-region-description,
.nav-desktop-territory:hover .nav-desktop-territory-description {
	display: block;
}

.nav-desktop-territory:hover > a,
.nav-desktop-territory:hover .nav-desktop-region:first-child > a,
.nav-desktop-region:hover > a {
	border-top: 2px solid @color-highlight;
}

.nav-desktop-territory:hover > a,
.nav-desktop-region:hover > a {
	background: #000;
	color: @color-highlight;
}

.nav-desktop-opener:hover > .nav-menu-item-link {
	background: rgba(36, 40, 42, 0.97);
	color: @color-highlight;
}

.nav-desktop-regions {
	position: absolute;
	right: -@nav-desktop-menu-width;
	top: -1px;
	// NOTE necessary because of the border-bottom of .nav-desktop-territory
	border-top: 1px solid @color-brand-grey;
}

.nav-desktop-regions:hover ~ .nav-desktop-territory-description {
	display: none;
}
