// Only Mobile
@media screen and (max-width: @breakpoint-mobile-max) {
	.nav {
		height: @nav-mobile-height;
		z-index: 2;
	}

	.nav-inner {
		padding: @padding-s;
		border-bottom: 1px solid @color-brand-grey;
	}

	.nav-button .fa-light {
		font-size: 30px;
		color: @color-brand-grey;
	}

	.nav-mobile-territory .nav-button .fa-light {
		width: 30px;
		font-size: 16px;
	}

	.nav-logo {
		height: 30px;
		margin-top: -12px;
		margin-right: -6px;
	}

	.nav-mobile-area {
		width: 100%;
		background: @color-white;
		position: absolute;
		transition: transform .3s ease;
		transform: scaleY(0);
		transform-origin: top;
	}

	.nav-toggle-button.textlink {
		text-decoration: none;
	}

	.nav-toggle-button .fa-light {
		margin-left: @padding-xs;
		font-weight: 600;
	}

	.nav-mobile-area.open {
		transform: scaleY(1);
	}

	.nav-mobile-territories,
	.nav-mobile-territory,
	.nav-mobile-territory-inner,
	.nav-mobile-regions,
	.nav-mobile-region {
		width: 100%;
	}

	.nav-mobile-territory {
		border-bottom: 1px solid @wp-nav-menu-border;
	}

	.nav-mobile-region {
		border-top: 1px solid @wp-nav-menu-border;
	}

	.nav-mobile-territory-inner {
		display: flex;
	}

	.nav-mobile-territory-inner.active {
		background: @wp-nav-menu-active-bg-color;
	}

	.nav-mobile-link {
		padding: @padding-i;
		flex-grow: 1;
		display: block;
	}

	.nav-mobile-territory .nav-button {
		padding: 3px @padding-i;
		border-left: 1px solid @wp-nav-menu-border;
		text-align: center;
	}

	.nav-mobile-region .nav-mobile-link {
		padding-left: @padding-l;
	}
}
